import { ContentRoles } from "@enums/Roles";
import React from "react";

export const ContentRoleSpecific: React.FC<{
  isPartner: boolean;
  userRole: ContentRoles;
  requiredRole: ContentRoles;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}> = ({ isPartner, userRole, requiredRole, children, fallback }) => {
  if (isPartner) {
    return <>{children}</>;
  } else {
    if (userRole >= requiredRole) {
      return <>{children}</>;
    } else if (fallback) {
      return <>{fallback}</>;
    } else return null;
  }
};
