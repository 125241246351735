import { ContentPermissions } from "@enums/Roles";
import {
  InfiniteData,
  keepPreviousData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";
import { AxiosError } from "axios";

export interface Filters {
  page: number;
  page_size: number;
  expand_ids?: string[];
  query?: string;
  columnVisibility?: string[];
}
export interface GetContentRequest extends Filters {}

export enum ContentStatus {
  "PUBLISHED" = "PUBLISHED",
  "DRAFT" = "DRAFT",
  "DISABLED" = "DISABLED",
  "IN_REVIEW" = "IN_REVIEW",
}

export enum ContentType {
  "MOVIE" = "MOVIE",
  "SERIES" = "SERIES",
  "MUSIC" = "MUSIC",
}

export enum SplitType {
  "PARTNER" = "PARTNER",
  "REFERRAL" = "REFERRAL",
  "ADVOCATE" = "ADVOCATE",
}

export enum ContentAccessType {
  "RENT" = "RENT",
  "PURCHASE" = "PURCHASE",
}

export interface ContentPrice {
  created_at: string;
  content_pricing_id: string;
  created_by: null;
  content_access_type: ContentAccessType;
  amount: number;
  currency: string;
}

export interface ContentSplit {
  base: string;
  split_type: SplitType;
  created_by: string;
  value: string;
  created_at: string;
}

export interface Content {
  analytics_id?: null;
  content_id: string;
  content_image_id: string;
  content_status: ContentStatus;
  content_type: ContentType;
  content_version: "TWO";
  created_at: string;
  created_by: string;
  description: string;
  duration_seconds: number;
  long_description: string;
  related_url: string;
  release_year: string;
  share_id?: string;
  title: string;
}

export interface ExpandedContent extends Content {
  analytics_id: undefined;
  share_id: undefined;
  content_image: {
    created_at: string;
    type: "POSTER_IMAGE";
    value: string;
    key: "IMAGE";
    created_by: string;
    content_object_id: string;
  };
  analytics: {
    total_purchases: number;
    total_earned: Price;
    total_partners: number;
  };
  share: {
    share_url?: string;
    splits: ContentSplit[] | null;
    prices: (ContentPrice | undefined)[];
    share_hash?: string;
  };
  rbac: {
    type: string;
    permissions: Array<`${ContentPermissions}`>;
  };
}

export interface GetContentResponse {
  data: ExpandedContent[];
  page: number;
  page_size: number;
  total_count: number;
  count: number;
  requested_on: string;
}

export const getContentList = async ({
  page,
  page_size,
  expand_ids = ["share_id", "analytics_id"],
  query,
}: GetContentRequest) => {
  const response = await client().get<GetContentResponse>(
    "v2/creators/contents",
    {
      params: {
        page,
        page_size,
        expand_ids: "share_id,analytics_id,content_image_id",
        search: query,
      },
    },
  );

  return response.data;
};

export const useGetContentList = (
  query: GetContentRequest,
  options?: Partial<
    UseQueryOptions<
      GetContentResponse,
      AxiosError<DefaultErrorResponse>,
      GetContentResponse
    >
  >,
) =>
  useQuery({
    queryFn: () => getContentList(query),
    queryKey: ["get", "content", query],
    placeholderData: keepPreviousData,
    staleTime: 10 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
    ...options,
  });

export const getContentInfiniteKey = ["get", "content", "infinite"];

export const useGetContentInfiniteList = (
  query: any,
  options?: Partial<
    Omit<
      UseInfiniteQueryOptions<
        GetContentResponse,
        AxiosError<DefaultErrorResponse>,
        InfiniteData<GetContentResponse>
      >,
      "queryFn"
    >
  >,
) =>
  useInfiniteQuery({
    queryKey: [...getContentInfiniteKey, query],
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam = 0, ...rest }, ...props) =>
      getContentList({ page: pageParam, page_size: 10, ...query }),
    initialPageParam: 1,
    getPreviousPageParam: (firstPage, pages) => {
      if (firstPage.page === 1) return undefined;

      return firstPage.page - 1;
    },
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.total_count <= lastPage.page * lastPage.page_size)
        return undefined;

      return lastPage.page + 1;
    },
    staleTime: 10 * 60 * 1000,
    gcTime: 60 * 60 * 1000,
    ...options,
  });
