import {
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { client } from "@utils/axios-utils";
import { AxiosError } from "axios";

export interface MediaKitData {
  data: {
    media_kit: string;
  };
}

export const getMediaKit = async ({ contentId }: { contentId: string }) => {
  const response = await client().get(
    `v2/master_distributors/contents/${contentId}/media_kit`,
  );
  return response.data;
};
