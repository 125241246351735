import Typography from "@components/Typography";
import Price from "./Price";
import { getItem } from "@utils/localStorage";
import { ExpandedContent, SplitType } from "@queries/creator/getContentList";
import SplitShare from "./SplitShare";

export const ContentCardSplits: React.FC<{ card: ExpandedContent }> = ({
  card,
}) => {
  return (
    <div className="flex flex-row gap-8">
      <div>
        <Typography
          tracking="tighter"
          className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
          type="body"
          size="xs"
        >
          Price
        </Typography>
        <Price
          prices={card.share?.prices}
          defaultCurrency={getItem("preferred-currency") ?? "USD"}
        />
      </div>
      <div>
        <Typography
          tracking="tighter"
          className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
          type="body"
          size="xs"
        >
          Split Partners
        </Typography>
        <SplitShare
          shares={card.share?.splits?.find(
            (split) => split.split_type === SplitType.PARTNER,
          )}
          size="md"
        />
      </div>
      <div>
        <Typography
          tracking="tighter"
          className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
          type="body"
          size="xs"
        >
          Split Referrals
        </Typography>
        <SplitShare
          shares={card.share?.splits?.find(
            (split) => split.split_type === SplitType.REFERRAL,
          )}
          size="md"
        />
      </div>
      <div>
        <Typography
          tracking="tighter"
          className="mb-1 leading-none text-[rgb(var(--media-gray-500))]"
          type="body"
          size="xs"
        >
          Non-Profit
        </Typography>
        <SplitShare
          shares={card.share?.splits?.find(
            (split) => split.split_type === SplitType.ADVOCATE,
          )}
          size="md"
        />
      </div>
    </div>
  );
};
