"use client";
import { MyButton } from "@components/Button";
import {
  ExpandedContent,
  useGetContentInfiniteList,
} from "@queries/creator/getContentList";
import { useGetContentInfiniteList as useGetPartnerContentInfiniteList } from "@queries/master_distributors/getContentList";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ContentCard from "@components/ContentCard/ContentCard";
import FilterPanel from "@components/ContentCard/FilterPanel";
import ContentStatusCard from "@components/ContentCard/ContentStatusCard";
import { useFilters } from "@hooks/useFilters";
import Typography from "@components/Typography";
import { useAuth } from "@context/Auth";
import { useRouter } from "next/navigation";
import Link from "next/link";
import RoleSpecific from "@components/RoleSpecific";
import { GetCreatorOnboardingStatus } from "@queries/creator/getOnboardStatus";
import { useDisclosure } from "@nextui-org/react";
import BaseModal from "@components/Modals/BaseModal";
import { useRequestContentUploadAndCreationMutation } from "@queries/__retool/requestContentUploadAndCreation";
import { setItem } from "@utils/localStorage";
import { Spinner } from "@nextui-org/spinner";
import { Roles } from "@enums/Roles";

export interface TokenInterface {
  firstName: string;
  lastName: string;
  email: string;
  embedUrl?: string | undefined;
}

const RootPage = () => {
  const [verificationStatus, setVerificationStatus] = useState<number>(0);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const router = useRouter();

  // Mutation to request new id content
  const requestContentId = useRequestContentUploadAndCreationMutation();

  // We need a reference to the scrolling element for logic down below
  const containerRef = useRef<HTMLDivElement>(null);

  const { user, role, logout } = useAuth();
  // Query to assert if user requires KYC/KYB
  const {
    data: onboardData,
    isFetching: onboardIsFetching,
    isError: onboardIsError,
  } = GetCreatorOnboardingStatus({
    enabled: !!user && role === Roles["creator.admin"],
  });
  const { setFilters, filters } = useFilters();
  const {
    data,
    error,
    fetchNextPage,
    refetch,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    isError,
    ...rest
  } = (
    role === Roles["creator.admin"]
      ? useGetContentInfiniteList
      : useGetPartnerContentInfiniteList
  )(filters, {
    enabled: !!user,
  });
  const allPages = useMemo(
    () =>
      data?.pages.reduce((acc, page) => {
        acc.push(...page.data);
        return acc;
      }, [] as ExpandedContent[]),
    [data],
  );

  //flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page.data) ?? [],
    [data],
  );
  const totalDBRowCount = data?.pages?.[0]?.total_count ?? 0;
  const totalFetched = flatData.length;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 500 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  const handleOnboardCreator = (step: number, object: TokenInterface) => {
    console.log("coding object: ", object);
    router.push(
      `/user-onboard?step=${step}&token=${btoa(JSON.stringify(object))}`,
      { scroll: true },
    );
  };

  // A check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  useEffect(() => {
    fetchMoreOnBottomReached(containerRef.current);
  }, [fetchMoreOnBottomReached]);

  // A check to see if creator has done KYC / KYB and which step remains to be done.
  useEffect(() => {
    // TODO - Temporary disable logic to not request KYC/KYB
    // if (isError) return;
    // if (!onboardData) return;
    //
    // console.log(onboardData);
    // if (onboardData.data === null) {
    //   // redirect user to onboard  - onboard url
    //   setVerificationStatus(1);
    //   onOpen();
    //   return;
    // }
    //
    // if (onboardData.verification === null) {
    //   // redirect user to kyc/kyb - same url as onboard diff step
    //   setVerificationStatus(2);
    //   onOpen();
    //   return;
    // }
    //
    // if (onboardData.verification.identity_status === "PENDING") {
    //   setVerificationStatus(2);
    //   onOpen();
    // }
  }, [onboardData, onboardIsError]);

  const navigateCreateContent = () => {
    requestContentId.mutateAsync(undefined, {}).then((response) => {
      console.log("request Content ID: ", response);
      setItem("content_id", response.data.content_id);
      router.push("/create-content", { scroll: true });
      refetch().then();
    });
  };

  const renderModalBody = () => {
    if (verificationStatus === 0) {
      return <></>;
    }
    if (verificationStatus === 1) {
      return (
        <div>
          <p>
            Help us verify your details! Please complete the KYC/KYB forms so we
            can keep things moving forward.
          </p>
        </div>
      );
    }
    if (verificationStatus === 2) {
      return (
        <div>
          <p>
            Seems we are missing a formal verification of yourself or you
            business.
          </p>
          <p>Please, in order to move forward we need to prove who you are.</p>
        </div>
      );
    }
  };

  return (
    <div
      ref={containerRef}
      onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
      className="h-full overflow-auto sm:px-6 sm:py-4 lg:px-[var(--media-spacing-114)] lg:py-[var(--media-spacing-32)]"
    >
      <div className="sticky top-0 z-20 mb-4 flex gap-2 backdrop-blur-sm sm:mb-6 sm:gap-4 lg:mb-[var(--media-spacing-32)]">
        <Typography type="display" size="md" className="shrink-0 grow">
          Home
        </Typography>
        <FilterPanel onChange={setFilters} values={filters} />
        <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
          <MyButton
            color="secondary"
            size="lg"
            as={Link}
            href="/invite-partner"
          >
            Invite Partners
          </MyButton>
        </RoleSpecific>
        <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
          <MyButton
            color="primary"
            size="lg"
            onPress={navigateCreateContent}
            isDisabled={requestContentId.isPending}
            isLoading={requestContentId.isPending}
            spinner={<Spinner size={"sm"} />}
          >
            Add Media Asset
          </MyButton>
        </RoleSpecific>
      </div>

      <div className="grid auto-rows-fr grid-cols-[repeat(auto-fill,_minmax(300px,_1fr))] justify-items-center gap-4 lg:grid-cols-[repeat(auto-fill,_minmax(400px,_1fr))]">
        {allPages?.map((card) => (
          <ContentCard
            key={card.content_id}
            card={card}
            onInvite={(id: string) => {
              router.push(`/invite-partner/${id}`);
            }}
          />
        ))}
        {allPages?.length === 0 && (
          <ContentStatusCard type="empty" data="No content" />
        )}
        {(status === "pending" || isFetching || onboardIsFetching) && (
          <ContentStatusCard type="loading" />
        )}
        {isError && <ContentStatusCard type="error" data={error as Error} />}
      </div>
      <BaseModal
        isDismissable={false}
        isKeyboardDismissDisabled={true}
        hideCloseButton={true}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        primaryCallback={() => {
          handleOnboardCreator(verificationStatus, {
            firstName: onboardData!.creator_first_name,
            lastName: onboardData!.creator_last_name,
            email: onboardData!.creator_email,
            embedUrl:
              (onboardData!.verification?.identity_url as string) ?? undefined,
          });
        }}
        secondaryCallback={() => {
          logout().then();
        }}
        title={"Complete Verification"}
        body={renderModalBody()}
        primaryCallbackTitle={"Take me there"}
        secondaryCallbackTitle={"Logout"}
      />
    </div>
  );
};

export default RootPage;
