import { MyButton } from "@components/Button";
import Typography from "@components/Typography";
import {
  Autocomplete,
  AutocompleteItem,
  Card,
  CardBody,
  Divider,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import { ExpandedContent } from "@queries/creator/getContentList";
import NextImage from "next/image";
import { setItem } from "@utils/localStorage";
import StatusBadge from "@components/ContentCard/StatusBadge";
import copy from "@utils/copy";
import { toast } from "sonner";
import NoImage from "/public/static/no-image.webp";
import { useRouter } from "next/navigation";
import dynamic from "next/dynamic";
import React, { useState } from "react";
import { useInviteCollaboratorMutation } from "@queries/creator/teamCollaborators/sendTeamInvite";
import { getRoleValue, Roles } from "@enums/Roles";
import { ContentCardHeader } from "./ContentCardHeader";
import { ContentCardFooter } from "./ContentCardFooter";
import { ContentCardSplits } from "./ContentCardSplits";
import RoleSpecific from "@components/RoleSpecific";

const Preview = dynamic(() => import("./Preview"), { ssr: false });

export const roles = [
  { label: "Manager", value: "MANAGER" },
  { label: "Editor", value: "EDITOR" },
  { label: "Viewer", value: "VIEWER" },
  { label: "Developer", value: "DEVELOPER" },
];

const developerPermissions = ["View film page preview"];

const viewerPermissions = [
  ...developerPermissions,
  "View content general analytics",
];

const editorPermissions = [
  ...viewerPermissions,
  "Design content page",
  "Build media kit for partner and advocates",
];

const managerPermissions = [
  ...editorPermissions,
  "Edit content data before release",
  "Invite collaborators",
  "Settings content price and restrictions",
  "Invite non-profits",
  "Invite partners",
  "Invite advocates",
  "View earnings",
  "View partner performance",
  "View users data",
  "Download users data",
  "Connect 3rd party services",
  "Receive e-mail notifications",
];

export const rolesPermissions = [
  { key: "MANAGER", permissions: managerPermissions },
  { key: "EDITOR", permissions: editorPermissions },
  { key: "VIEWER", permissions: viewerPermissions },
  { key: "DEVELOPER", permissions: developerPermissions },
];

const ContentCard: React.FC<{
  card: ExpandedContent;
  onInvite: (content_id: string) => void;
}> = ({ card, onInvite }) => {
  const router = useRouter();
  const { isOpen, onOpenChange, onClose, onOpen } = useDisclosure();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [invitationRole, setInvitationRole] = useState("MANAGER");

  const inviteMutation = useInviteCollaboratorMutation();

  const roleValue = getRoleValue(card.rbac?.type) ?? 0;

  const handleCopy = () => {
    if (typeof window === "undefined") return;

    copy(card.share?.share_url ?? "no url");

    toast.success("Url copied to clip board!", { id: "share_url_copy" });
  };

  const handleResumeCardProgress = () => {
    setItem("content_id", card.content_id);
    router.push("/create-content", { scroll: true });
  };

  const handleCollaboratorInvite = () => {
    inviteMutation
      .mutateAsync({
        collaborator_email: email,
        collaborator_first_name: name.split(" ")[0],
        collaborator_last_name: name.split(" ")[1] ? name.split(" ")[1] : "",
        content_id: card.content_id,
        collaborator_type: invitationRole,
      })
      .then(() => {
        toast.success("Team member invited!");
        onClose();
      });
  };

  return (
    <Card
      shadow="none"
      className="min-w-full border-1 border-[rgb(var(--media-gray-200))] bg-[rgb(var(--media-gray-50))] sm:max-w-[400px] lg:max-w-none"
      classNames={{
        header: "p-4 bg-background-olyn",
      }}
    >
      <ContentCardHeader
        card={card}
        userRole={roleValue}
        onInviteTeamMemberClick={onOpen}
      />
      <Divider />
      <div className="relative aspect-video w-full">
        <div className="absolute left-4 top-4 z-10 flex flex-row items-start justify-center gap-3">
          <StatusBadge classNames={{ base: "" }} status={card.content_status} />
          <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
            <Preview content_id={card.content_id} />
          </RoleSpecific>
        </div>
        {card.content_image ? (
          <Image
            as={NextImage}
            src={card.content_image?.value}
            objectFit="cover"
            fill
            alt={card.title}
            radius="none"
            classNames={{ wrapper: "z-0 w-full h-full !max-w-none" }}
          />
        ) : (
          <NextImage
            src={NoImage}
            fill
            className="object-cover opacity-20"
            alt={card.title}
          />
        )}
        <div className="z-1 absolute bottom-0 w-full bg-content-card px-4 pb-3">
          <h2 className="text-[2rem] font-medium not-italic leading-none tracking-tight text-[rgb(var(--media-gray-800))]">
            {card.title}
          </h2>
        </div>
      </div>
      <Divider />
      <div className="flex flex-col justify-start">
        <CardBody>
          <ContentCardSplits card={card} />
        </CardBody>
        <Divider />
      </div>
      <ContentCardFooter
        card={card}
        userRole={roleValue}
        onResumeCardProgress={handleResumeCardProgress}
        onInvitePartners={() => onInvite(card.content_id)}
        onCopyLink={handleCopy}
      />

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col items-center bg-[#F9FAFB] text-center">
                <Typography type={"display"} size={"xxs"}>
                  Invite Team members
                </Typography>
              </ModalHeader>
              <ModalBody className="pb-4">
                <Input
                  key={"full-name"}
                  type="text"
                  label="Full name"
                  labelPlacement={"outside"}
                  placeholder="Enter your Full name"
                  value={name}
                  onValueChange={setName}
                />
                <Input
                  key={"email"}
                  type="email"
                  label="Email"
                  labelPlacement={"outside"}
                  placeholder="Enter your email"
                  value={email}
                  onValueChange={setEmail}
                />
                <Autocomplete
                  defaultItems={roles}
                  defaultSelectedKey={"MANAGER"}
                  label="Role"
                  placeholder="Select a role"
                  labelPlacement={"outside"}
                  classNames={{
                    base: "w-full",
                  }}
                  selectedKey={invitationRole}
                  onSelectionChange={(value) => {
                    setInvitationRole((value ? value : "VIEWER") as string);
                  }}
                >
                  {(role) => (
                    <AutocompleteItem key={role.value}>
                      {role.label}
                    </AutocompleteItem>
                  )}
                </Autocomplete>

                <div className="flex flex-col gap-[0.875rem] pt-2">
                  <Tooltip
                    content={"Permission you will be granting to the colla"}
                  >
                    <Typography type={"body"} weight={"semibold"} size={"md"}>
                      {invitationRole.charAt(0).toUpperCase() +
                        invitationRole.slice(1).toLowerCase()}{" "}
                      Permissions
                    </Typography>
                  </Tooltip>
                  <div className="flex h-[10rem] flex-col items-start justify-start gap-2 overflow-y-auto">
                    {rolesPermissions
                      .find((item) => item.key === invitationRole)
                      ?.permissions.map((value, index) => {
                        return (
                          <Typography key={index} type={"body"} size={"md"}>
                            · {value}
                          </Typography>
                        );
                      })}
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <MyButton
                  color={"secondary"}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </MyButton>
                <MyButton
                  color="primary"
                  onClick={handleCollaboratorInvite}
                  isDisabled={
                    inviteMutation.isPending ||
                    name.length === 0 ||
                    email.length === 0
                  }
                  isLoading={inviteMutation.isPending}
                >
                  Continue
                </MyButton>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default ContentCard;
