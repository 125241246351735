import { MyButton } from "@components/Button";
import { ContentRoleSpecific } from "@components/ContentRoleSpecific";
import RoleSpecific from "@components/RoleSpecific";
import Typography from "@components/Typography";
import { useAuth } from "@context/Auth";
import { ContentRoles, Roles } from "@enums/Roles";
import Copy from "@icons/Copy";
import { CardFooter, Input } from "@nextui-org/react";
import {
  ContentStatus,
  ExpandedContent,
} from "@queries/creator/getContentList";
import { getMediaKit } from "@queries/master_distributors/getMediaKit";

export const ContentCardFooter: React.FC<{
  card: ExpandedContent;
  onResumeCardProgress: () => void;
  onCopyLink: () => void;
  onInvitePartners: () => void;
  userRole: ContentRoles;
}> = ({
  card,
  onResumeCardProgress,
  onCopyLink,
  userRole,
  onInvitePartners,
}) => {
  const { role } = useAuth();

  const isPartner = role === Roles["md.admin"];

  const onDownloadMediaKit = async () => {
    const {
      data: { media_kit },
    } = await getMediaKit({ contentId: card.content_id });

    window.open(media_kit, "_blank");
  };

  return (
    <CardFooter
      className={`justify-${card.content_status === ContentStatus.DRAFT ? "end" : "start"} grow flex-col items-start gap-4 px-4 pb-9 pt-8`}
    >
      {card.content_status === ContentStatus.DRAFT && (
        <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
          <ContentRoleSpecific
            isPartner={isPartner}
            userRole={userRole}
            requiredRole={ContentRoles.EDITOR}
          >
            <MyButton fullWidth color="primary" onPress={onResumeCardProgress}>
              Resume
            </MyButton>
          </ContentRoleSpecific>
        </RoleSpecific>
      )}
      {card.content_status === ContentStatus.PUBLISHED && (
        <>
          <Typography type="body" size="sm" className="px-2 text-left">
            Your Film Link to Share
          </Typography>

          <div className="flex w-full gap-4">
            <Input
              readOnly
              isDisabled={card.content_status !== ContentStatus.PUBLISHED}
              defaultValue={
                card.content_status === ContentStatus.PUBLISHED
                  ? card.share?.share_url!
                  : "Content not yet published."
              }
              variant="bordered"
              onClick={onCopyLink}
            />
            <MyButton
              color="secondary"
              isIconOnly
              isDisabled={card.content_status !== ContentStatus.PUBLISHED}
              startContent={<Copy color="currentColor" />}
              onPress={onCopyLink}
            />
          </div>

          <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
            <ContentRoleSpecific
              isPartner={isPartner}
              userRole={userRole}
              requiredRole={ContentRoles.MANAGER}
            >
              <Typography type="body" size="sm" className="px-2 text-left">
                Invite Partners to Share your content
              </Typography>

              <MyButton
                fullWidth
                color="primary"
                isDisabled={card.content_status !== ContentStatus.PUBLISHED}
                onPress={onInvitePartners}
              >
                + Invite Partners
              </MyButton>
            </ContentRoleSpecific>
          </RoleSpecific>

          <RoleSpecific acceptOnly={[Roles["md.admin"]]}>
            <MyButton
              fullWidth
              color="primary"
              isDisabled={card.content_status !== ContentStatus.PUBLISHED}
              onPress={onDownloadMediaKit}
            >
              Access Media Kit
            </MyButton>
          </RoleSpecific>
        </>
      )}
    </CardFooter>
  );
};
