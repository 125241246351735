import { MyButton } from "@components/Button";
import { ContentRoleSpecific } from "@components/ContentRoleSpecific";
import RoleSpecific from "@components/RoleSpecific";
import Typography from "@components/Typography";
import { useAuth } from "@context/Auth";
import { ContentRoles, Roles } from "@enums/Roles";
import ArrowUpRight from "@icons/ArrowUpRight";
import TeamIcon from "@icons/TeamIcon";
import { CardHeader } from "@nextui-org/react";
import {
  ContentStatus,
  ExpandedContent,
} from "@queries/creator/getContentList";
import { fromCents } from "@utils/fromCents";
import { getCurrencyFormat } from "@utils/getCurrency";
import Link from "next/link";

export const ContentCardHeader: React.FC<{
  card: ExpandedContent;
  onInviteTeamMemberClick: () => void;
  userRole: ContentRoles;
}> = ({ card, onInviteTeamMemberClick, userRole }) => {
  const { role } = useAuth();
  const isPartner = role === Roles["md.admin"];

  return (
    <CardHeader className="flex-wrap items-end justify-between gap-4">
      <div className="flex flex-col gap-4">
        <div className="flex flex-row items-center justify-start gap-8">
          <RoleSpecific acceptOnly={[Roles["creator.admin"]]}>
            <ContentRoleSpecific
              isPartner={isPartner}
              userRole={userRole}
              requiredRole={ContentRoles.MANAGER}
            >
              <div
                className="rounded-lg border-1 bg-white p-2"
                role={"button"}
                onClick={onInviteTeamMemberClick}
              >
                <TeamIcon />
              </div>
            </ContentRoleSpecific>
          </RoleSpecific>
          <ContentRoleSpecific
            isPartner={isPartner}
            userRole={userRole}
            requiredRole={ContentRoles.VIEWER}
            fallback={<div />}
          >
            <div>
              <Typography
                className="mb-1 leading-none tracking-[-0.03em] text-[rgb(var(--media-gray-500))]"
                type="body"
                size="xs"
              >
                Total earned
              </Typography>
              <Typography
                className="tracking-[-0.03em] text-[rgb(var(--media-gray-800))]"
                type="body"
                size="md"
              >
                {(card.analytics?.total_earned &&
                  getCurrencyFormat(
                    card.analytics?.total_earned.currency,
                  ).format(fromCents(card.analytics?.total_earned.amount))) ??
                  "-"}
              </Typography>
            </div>
          </ContentRoleSpecific>
          <ContentRoleSpecific
            isPartner={isPartner}
            userRole={userRole}
            requiredRole={ContentRoles.VIEWER}
            fallback={<div />}
          >
            <div>
              <Typography
                className="mb-1 leading-none tracking-[-0.03em] text-[rgb(var(--media-gray-500))]"
                type="body"
                size="xs"
              >
                Total views
              </Typography>
              <Typography
                className="tracking-[-0.03em] text-[rgb(var(--media-gray-800))]"
                type="body"
                size="md"
              >
                {card.analytics?.total_purchases ?? "-"}
              </Typography>
            </div>
          </ContentRoleSpecific>
          <ContentRoleSpecific
            isPartner={isPartner}
            userRole={userRole}
            requiredRole={ContentRoles.MANAGER}
            fallback={<div />}
          >
            <div>
              <Typography
                className="mb-1 leading-none tracking-[-0.03em] text-[rgb(var(--media-gray-500))]"
                type="body"
                size="xs"
              >
                Active partners
              </Typography>
              <Typography
                className="tracking-[-0.03em] text-[rgb(var(--media-gray-800))]"
                type="body"
                size="md"
              >
                {card.analytics?.total_partners ?? "-"}
              </Typography>
            </div>
          </ContentRoleSpecific>
        </div>
        <ContentRoleSpecific
          isPartner={isPartner}
          userRole={userRole}
          requiredRole={ContentRoles.VIEWER}
        >
          <div className="flex flex-row items-center justify-start gap-8">
            <MyButton
              className="shrink-o"
              color="secondary"
              size="sm"
              as={Link}
              isDisabled={card.content_status !== ContentStatus.PUBLISHED}
              href={`/analytics?content_id=${card.content_id}`}
              endContent={<ArrowUpRight color="currentColor" />}
            >
              Analytics
            </MyButton>
          </div>
        </ContentRoleSpecific>
      </div>
    </CardHeader>
  );
};
